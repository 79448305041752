import { useUserStore } from '~/stores/user';
import { useModalStore } from '~/stores/modal';
import { useMediaStore, type Media } from '~/stores/media';
import clone from 'clone';
import startCase from 'lodash/startCase';

export const useAgentStore = defineStore('agent', () => {
    const { 
      public: { 
        gcp: {
          storage: {
            endpoint: gcpEndpoint,
          }
        }, 
      }
    } = useRuntimeConfig();
    const toast = useToast()
    const route = useRoute()
    const { $supabaseClient, $toast } = useNuxtApp();
    const userStore = useUserStore();
    const modalStore = useModalStore();
    const mediaStore = useMediaStore();
    
    const allAgent = ref<Agent[]>([]);
    const agent = ref<Agent>({});
    const editingAgent = reactive<Agent>({});

    /**
     * Branch
     */
    const defaultBranchAction = computed<BranchAction>(() => ({
        action: 'pass-to-human-agent',
        assignmentMethod: 'round-robin',
        availableUserIds: (userStore?.workspaceMembers ?? []).map(({ id }) => id),
        userId: '',
        message: '',
    }))

    //* sources
    const sources = ref<AgentSource[]>([]);
    const originalSources = ref<AgentSource[]>([]);
    const trainingSourceIds = ref<string[]>([]);
    const trainedSourceIds = ref<string[]>([]);
    const failedSourceIds = ref<string[]>([]);
    const terminatedSourceIds = ref<string[]>([]);
    const deletedSources = ref<AgentSource[]>([]);
    const editingSource = ref<AgentSource>(defaultAgentSource)
    
    //* training
    const isAgentTrained = ref(false);
    const isTrainingInProgress = ref(false);
    const planTrainingCharacterLimit = ref(0);

    const modelOptions = ref<Model[]>([]);
    const isShowSourceModal = ref(false);
    const sourceModalStep = ref(1);

    const agentIdNamePairs = computed(() => {
        return allAgent.value.reduce((acc, agent) => {
            acc[agent.id] = agent.name;
            return acc;
        }, {} as { [key: string]: string });
    })

    const sourceWithStatus = computed(() => {
        const sourceIds = (sources.value ?? []).map((source) => source.id);
        const originalSourceIds = (originalSources.value ?? []).map((source) => source.id);
        const allSourceIds = new Set([...originalSourceIds, ...sourceIds])
        return [...allSourceIds].map((id: string) => {
            let status; // new, updated, trained, failed, untrained, deleted
            const currentSource = sources.value.find((source) => source.id === id);
            const originalSource = originalSources.value.find((source) => source.id === id);
            const source = currentSource ?? originalSource;
            
            if (trainingSourceIds.value.includes(id)) {
                status = 'training';
            }

            // if not existed in originalSourceIds, status = 'new'
            else if (!originalSourceIds.includes(id)) {
                status = 'new';
            }
            // if existed in originalSourceIds but not exists in sources.value, status = 'deleted'
            else if (!sourceIds.includes(id)) {
                status = 'deleted';
            }

            else if (failedSourceIds.value.includes(id)) {
                status = 'failed';
            }

            else if (terminatedSourceIds.value.includes(id)) {
                status = 'terminated';
            }

            // if exists in originalSourceIds and sources.value
            else {
                let isUpdated = false;
                switch (currentSource?.type) {
                    case "website":
                        isUpdated = currentSource?.url !== originalSource?.url;
                        break;
                    case "file":
                        isUpdated = currentSource?.file_path !== originalSource?.file_path;
                        break;
                    case "q&a":
                        isUpdated = currentSource?.question !== originalSource?.question || currentSource?.answer !== originalSource?.answer;
                        break;
                    case "text":
                    case "testimonial":
                        isUpdated = currentSource?.content !== originalSource?.content || currentSource?.name !== originalSource?.name;
                        break;
                    case "product":
                        isUpdated = JSON.stringify(currentSource) !== JSON.stringify(originalSource);
                        break;
                }

                if (isUpdated) {
                    status = "updated";
                } else {
                    status = trainedSourceIds.value.includes(id)
                        ? "trained"
                        : "untrained"
                }
            }

            return {
                ...source,
                status,
                displayType: source?.type === 'q&a' ? 'Q&A' : startCase(source?.type),
                displayName: source?.name,
                ...(source?.type === "website" && {
                    displayName: source.url,
                    href: source.url,
                }),
                ...(source?.type === "q&a" && {
                    displayName: source.question,
                }),
                ...(source?.type === "file" && {
                    href: `${gcpEndpoint}/${source.file_path}`,
                })
            }
        }).sort((a, b) => {
            return new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime();
        })
    })  

    const trainingTotalCharacters = computed(() => {
        return sources.value.reduce((acc, curr) => acc + curr.character_count, 0);
    })

    const isTotalTrainingCharacterLimitReached = computed(() => {
        if (planTrainingCharacterLimit.value === 0) return false;
        return trainingTotalCharacters.value > planTrainingCharacterLimit.value;
    })

    const hasUnsavedSources = computed(() => {
        return sourceWithStatus.value.some((source) => ['new', 'updated', 'deleted'].includes(source.status));
    })

    const canTrainAgent = computed(() => {
        const hasSourcesToTrain = sourceWithStatus.value.some((source) => source.status !== 'trained');
        return !isTotalTrainingCharacterLimitReached.value
            && !isTrainingInProgress.value
            && hasSourcesToTrain
            && sources.value.length > 0;
    })

    const isConfigurationHasChanges = computed(() => {
        return (agent.value?.name ?? '').trim() !== (editingAgent?.name ?? '').trim()
            || (agent.value?.display_name ?? '').trim() !== (editingAgent?.display_name ?? '').trim()
            || (agent.value?.should_make_introduction ?? false) !== (editingAgent?.should_make_introduction ?? false)
            || (agent.value?.is_default ?? false) !== (editingAgent?.is_default ?? false)
            || (agent.value?.trigger ?? '') !== (editingAgent?.trigger ?? '')
            || (agent.value?.role ?? '') !== (editingAgent?.role ?? '')
            || (agent.value?.goal ?? '') !== (editingAgent?.goal ?? '')
            || (agent.value?.brand_name ?? '') !== (editingAgent?.brand_name ?? '')
            || (agent.value?.brand_description ?? '') !== (editingAgent?.brand_description ?? '')
            || (agent.value?.brand_usps ?? []).length !== (editingAgent?.brand_usps ?? []).length
            || JSON.stringify(agent.value?.branch_configurations ?? {}) !== JSON.stringify(editingAgent?.branch_configurations ?? {});
    })

    async function fetchFirstAgent() {        
        const { data, error } = await $supabaseClient
            .from('agents')
            .select(`
                id
            `)
            .eq('workspace_id', userStore.user?.workspaceId)
            .limit(1);
        if (error) throw error;
        allAgent.value = data;
    }

    async function fetchAllAgent() {
        const { data, error } = await $supabaseClient
            .from('agents')
            .select(`
                id,
                name,
                is_default,
                created_at,
                agent_trainings (
                    created_at
                )
            `)
            .eq('workspace_id', userStore.user?.workspaceId)
            .eq('agent_trainings.status', 'completed')
            .order('created_at', {
                ascending: false,
                referencedTable: 'agent_trainings',
            })
            .limit(1, {
                referencedTable: 'agent_trainings',
            });
        if (error) throw error;
        allAgent.value = data;
    }

    async function fetchAgent() {
        const { data, error } = await $supabaseClient
            .from('agents')
            .select(`
                *,
                agent_sources (
                    *,
                    agent_embeddings (count),
                    agent_source_medias (*)
                ),
                agent_trainings (
                    status,
                    created_at,
                    status_updated_at,
                    agent_training_source (
                        agent_source_id,
                        status
                    )
                )
            `)
            .eq('id', route.params.id)
            .order('created_at', {
                referencedTable: "agent_sources",
                ascending: false,
            })
            .order("created_at", {
                referencedTable: "agent_trainings",
                ascending: false,
            })
            .limit(1, {
                referencedTable: 'agent_trainings',
            })
            .maybeSingle();

        if (error) throw error;
        if (!data) return;

        const {
            status,
            created_at,
            status_updated_at,
            agent_training_source = [],
        } = data?.agent_trainings?.[0] ?? {};
        agent.value = clone({
            ...data,
            status,
            status_updated_at,
            training_started_at: created_at,
        });
        Object.assign(editingAgent, data);
        sources.value = data?.agent_sources || [];
        originalSources.value = clone(sources.value);
        isAgentTrained.value = data?.agent_trainings?.length > 0;
        isTrainingInProgress.value = status === "in_progress";
        trainedSourceIds.value = (data?.agent_sources ?? []).filter((source) => source.agent_embeddings?.[0]?.count > 0).map((source) => source.id) || [];
        trainingSourceIds.value = agent_training_source.filter((source) => ['in_progress', 'queued'].includes(source.status)).map((source) => source.agent_source_id) || [];
        failedSourceIds.value = agent_training_source.filter((source) => source.status === "failed").map((source) => source.agent_source_id) || [];
        terminatedSourceIds.value = agent_training_source.filter((source) => source.status === "terminated").map((source) => source.agent_source_id) || [];
    }

    watch([() => userStore.workspaceMembers, () => Object.keys(editingAgent).length > 0], ([_, hasEditingAgent]) => {
        if (hasEditingAgent && !editingAgent?.branch_configurations) {
            editingAgent.branch_configurations = {
                'idle': clone(defaultBranchAction.value),
                'fail_to_respond': clone(defaultBranchAction.value),
                'ask_for_human_agent': clone(defaultBranchAction.value),
            }
        }
    })

    async function upsertSource(source: AgentSource) {
        let filePath = '';
        let agentSourceImagesToInsert: Media[] = []
        let agentSourceImageIdsToDelete: string[] = []
        
        if (source.type === 'file') {
            const storedFile = await mediaStore.uploadFile({
                file: source.file,
                folder: agent.value?.id,
                category: "agent-sources",
            }); 
            filePath = storedFile.path;
        } else if (source.type === 'product') {
            agentSourceImagesToInsert = (source?.agent_source_medias ?? []).filter((image) => !image.id);
            const existingSourceImageIds = (source?.agent_source_medias ?? []).filter((image) => image.id).map((image) => image.id);
            const originalSourceImages = originalSources.value.find((source) => source.id === source.id)?.agent_source_medias ?? []
            agentSourceImageIdsToDelete = originalSourceImages.filter((image) => !existingSourceImageIds.includes(image.id)).map((image) => image.id);
            // console.log({
            //     source: source?.agent_source_medias,
            //     agentSourceImagesToInsert,
            //     existingSourceImageIds,
            //     originalSourceImages,
            //     agentSourceImageIdsToDelete,
            // })
        }

        const { data, error } = await $supabaseClient
            .from('agent_sources')
            .upsert({
                name: null,
                content: null,
                question: null,
                answer: null,
                ...(source.id ? { id: source.id } : {}),
                ...(source.type === "website" && { url: source.url }),
                ...(source.type === "file" && {
                    name: source.name,
                    file_path: filePath,
                }),
                ...(source.type === "q&a" && {
                    question: source.question,
                    answer: source.answer,
                }),
                ...(source.type === "text" && {
                    name: source.name,
                    content: source.content,
                }),
                ...(source.type === "testimonial" && {
                    name: source.name,
                    content: source.content,
                }),
                ...(source.type === "product" && {
                    name: source.name,
                    content: source.content,
                    pricing: source.pricing,
                    is_active: source.is_active,
                    is_featured: source.is_featured,
                }),
                agent_id: agent.value.id,
                type: source?.type || null,
                character_count: source?.character_count || 0,
                updated_at: new Date().toISOString(),
            })
            .select("id")
            .single();

        if (error) throw error;

        if (agentSourceImageIdsToDelete.length > 0) {
            await Promise.all(agentSourceImageIdsToDelete.map((id) => $supabaseClient
                .from('agent_source_medias')
                .delete()
                .eq('id', id)
                .eq('agent_source_id', data.id)
                .eq('workspace_id', userStore.user?.workspaceId)
            ));
        }
        
        if (agentSourceImagesToInsert.length > 0) {
            await Promise.all(agentSourceImagesToInsert.map((image) => $supabaseClient
                .from('agent_source_medias')
                .insert({
                    name: image.name,
                    url: image.url,
                    file_size: image.file_size,
                    mime_type: image.mime_type,
                    extension: image.extension,
                    width: image.width,
                    height: image.height,
                    agent_source_id: data.id,
                    workspace_id: userStore.user?.workspaceId,
                })
            ));
        }
    }

    async function deleteSource(id: string) {
        try {
            await $fetch('/api/agent/source', {
                method: 'DELETE',
                query: {
                    id
                }
            });
            sources.value = sources.value.filter((source) => source.id !== id);
        } catch (error) {
            $toast.error('Failed to delete source');
            throw error;
        }
    }

    async function saveSource() {
        const sourcesToUpsert = sourceWithStatus.value.filter((source) => source.status === "new" || source.status === "updated");
        const sourcesToDelete = sourceWithStatus.value.filter((source) => source.status === "deleted");
        // console.log({
        //     sourcesToUpsert,
        //     sourcesToDelete,
        // })

        await Promise.all([
            ...sourcesToUpsert.map(source => upsertSource(source)),
            ...sourcesToDelete.map(source => deleteSource(source.id)),
        ])
    }

    async function updateAgent(values: Agent) {
        const { error } = await $supabaseClient
            .from('agents')
            .update(values)
            .eq('id', agent.value.id);
        if (error) throw error;
        agent.value = {
            ...agent.value,
            ...values,
        }
    }

    async function trainAgent() {
        isTrainingInProgress.value = true;
        try {
            trainingSourceIds.value = sourceWithStatus.value
                .filter((source) => ['new', 'updated', 'failed', 'untrained'].includes(source.status))
                .map((source) => source.id);
            const { error } = await $supabaseClient.functions.invoke('agent-training-trigger', {
                body: {
                    agentId: agent.value.id,
                }
            })

            if (error) throw error;

            toast.add({
                id: 'training_in_progress',
                title: 'Agent training in progress.',
                description: 'We will notify you when the training is completed. Feel free to navigate away from this page.',
                icon: 'i-lucide-brain-circuit',
                ui: {
                    icon: {
                        base: "mt-1",
                    }
                }
            })
        } catch (error) {
            $toast.error('Failed to train agent. Please try again later or contact support.');
            throw error;
        }
    }

    function toggleSourceModal() {
        isShowSourceModal.value = !isShowSourceModal.value;
        sourceModalStep.value = 1;
    }

    function triggerDeleteConfirmationModal(id: string, name: string) {
        modalStore.open(id, 'agent source', name);
    }

    async function switchDefaultAgent(agentId: string) {
        try {
            const { error: disableOldDefaultAgentError } = await $supabaseClient
                .from('agents')
                .update({
                    is_default: false,
                })
                .eq('workspace_id', userStore.user?.workspaceId);
            if (disableOldDefaultAgentError) throw disableOldDefaultAgentError;
            const { error } = await $supabaseClient
                .from('agents')
                .update({
                    is_default: true,
                })
                .eq('id', agentId);
            if (error) throw error;
        } catch (error) {
            throw error;
        }
    }

    return {
        agent,
        allAgent,
        editingAgent,
        isAgentTrained,
        modelOptions,
        isShowSourceModal,
        sourceModalStep,
        editingSource,
        agentIdNamePairs,
        //* sources
        sources,
        sourceWithStatus,
        trainingSourceIds,
        failedSourceIds,
        terminatedSourceIds,
        deletedSources,
        hasUnsavedSources,
        //* training
        canTrainAgent,
        isTrainingInProgress,
        trainedSourceIds,
        planTrainingCharacterLimit,
        isTotalTrainingCharacterLimitReached,
        trainingTotalCharacters,
        isConfigurationHasChanges,
        fetchAgent,
        fetchFirstAgent,
        fetchAllAgent,
        toggleSourceModal,
        saveSource,
        updateAgent,
        trainAgent,
        triggerDeleteConfirmationModal,
        switchDefaultAgent,
    };
});

export const defaultAgentSource = {
    id: '',
    name: '',
    question: '',
    answer: '',
    type: '',
    displayType: '',
    content: '',
    url: '',
    created_at: '',
    file_path: '',
    pricing: '',
    character_count: 0,
    is_active: true,
    is_featured: false,
}

export type Branch = 'idle' | 'fail_to_respond' | 'ask_for_human_agent';

export interface BranchSetupOption {
  label: string;
  branch: Branch;
  icon: string;
}

export interface BranchAction {
    action: 'close-conversation' | 'pass-to-human-agent';
    assignmentMethod: 'round-robin' | 'specific-user';
    availableUserIds?: string[];
    userId?: string;
    message?: string;
}

export interface Agent {
    id: string;
    name: string;
    display_name: string;
    is_default: boolean;
    trigger: string | null;
    should_make_introduction: boolean;
    role: "support" | "sales";
    goal: string;
    created_at: string;
    status: string;
    training_started_at: string;
    status_updated_at: string;
    brand_name?: string;
    brand_description?: string;
    brand_usps?: string[];
    branch_configurations: Record<Branch, BranchAction>;
    agent_sources: AgentSource[];
}

export interface AgentSource {
    id?: string;
    name?: string;
    type: string;
    displayType?: string;
    content?: string;
    question?: string;
    answer?: string;
    file_path?: string;
    character_count: number;
    url?: string;
    pricing?: string;
    is_active: boolean;
    is_featured: boolean;
    agent_source_medias: any[];
    created_at?: string;
}

interface Model {
    label: string;
    value: string;
    is_premium: boolean;
    description: string;
}

export const sourceTypeLabels = {
	"q&a": "Q&A",
	"website": "Website",
	"file": "File",
    "text": "Text snippet",
    "testimonial": "Testimonial",
    "product": "Product or Service",
}